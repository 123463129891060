import {
    ASSISTENCIA_ACOMPANHAMENTO,
    ASSISTENCIA_DETALHES_PROTOCOLO,
    ASSISTENCIA_ACOMPANHAMENTO_LOADING,
    ASSISTENCIA_DETALHES_LOADING,
    ASSISTENCIA_RESPOSTA_LOADING,
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    acompanhamento: [],
    detalhesprotocolo: [],
    loadingList: true,
    loadingDetalhes: false,
    loadingResposta: false
}
//eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ASSISTENCIA_ACOMPANHAMENTO:
            return { ...state, acompanhamento: action.payload }

        case ASSISTENCIA_DETALHES_PROTOCOLO:
            return { ...state, detalhesprotocolo: action.payload }

        case ASSISTENCIA_ACOMPANHAMENTO_LOADING:
            return { ...state, loadingList: action.payload }

        case ASSISTENCIA_DETALHES_LOADING:
            return { ...state, loadingDetalhes: action.payload }


        case ASSISTENCIA_RESPOSTA_LOADING:
            return { ...state, loadingResposta: action.payload }
        default:

            return state; // Retorne o estado atual por padrão
    }

}