import React from 'react';
import { connect } from 'react-redux';
import './Acompanhamento.css'
import { useNavigate } from 'react-router-dom';
import { DetalhesProtocoloAction } from '../../../actions/AcompanhamentoActions';




function AcompanhamentoCardMobile(props) {

    const { loadingDetalhes, DetalhesProtocoloAction } = props

    const navigate = useNavigate();

    const handleRowClick = (protocolo) => {
        if (!loadingDetalhes) {

            DetalhesProtocoloAction(protocolo).then(() => {
                navigate(`/assistencia/acompanhamento/${protocolo}`);
                window.scrollTo(0, 0);

            }).catch(() => {

            });


        }
    };


    const getStatusClass = (status) => {
        switch (status) {
            case 1:
                return 'status-em-analise';
            case 2:
                return 'status-aguardando-cliente';
            case 3:
                return 'status-aguardando-tecnico';
            case 4:
                return 'status-procedente';
            case 5:
                return 'status-improcedente';
            default:
                return 'status-aguardando-analise';
        }
    };


    return (
        <div className='info-card-mobile-acompanhamento'>
            <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                <h4>Nome do Produto</h4>
                <p style={{ color: '#363636' }}>{props.data.produto}</p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Quantidade:</h4>
                <p style={{ color: '#363636' }}>{props.data.qntdade}</p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Nota Fiscal</h4>
                <p style={{ color: '#363636' }}>{props.data.numnota}</p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Data de Abertura</h4>
                <p style={{ color: '#363636' }}>{new Date(props.data.dtcriacao).toLocaleDateString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                })}</p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Protocolo</h4>
                <p style={{ color: '#363636' }}>{props.data.protocolo}</p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Status da Assistência</h4>
                <p style={{ color: '#363636' }}><span className={`status-label ${getStatusClass(props.data.status)}`}>
                    {props.data.status === 0 ? 'Aguardando Análise' :
                        props.data.status === 1 ? 'Em análise' :
                            props.data.status === 2 ? 'Aguardando Cliente' :
                                props.data.status === 3 ? 'Aguardando Técnico' :
                                    props.data.status === 4 ? 'Procedente' :
                                        props.data.status === 5 ? 'Improcedente' : ''}
                </span></p>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <h4>Ação:</h4>
                <div>
                    {loadingDetalhes ? (
                        <span className={`assistencia-button loading-spinner-detalhes-mobile`}></span>
                    ) : (
                        <>
                            <button
                                className="assistencia-button" loading={loadingDetalhes} onClick={() => handleRowClick(props.data.protocolo)}
                            >
                                Abrir Assistência
                            </button>
                        </>

                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    acompanhamento: state.AcompanhamentoReducer.acompanhamento,
    loadingDetalhes: state.AcompanhamentoReducer.loadingDetalhes,

});

export default connect(mapStateToProps, {
    DetalhesProtocoloAction
})(AcompanhamentoCardMobile);

