import React from 'react';
import ReactDOM from 'react-dom/client'; // Atualize a importação
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';
import * as serviceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Usa o armazenamento padrão (localStorage para web)
import { PersistGate } from 'redux-persist/integration/react';
import reducers from './reducers';
import ReduxThunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';

const logger = store => next => action => {
  // console.group(action.type)
  // console.info('dispatching', action)
  let result = next(action)
  // console.log('next state', store.getState())
  // console.groupEnd(action.type)
  return result
}

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  {},
  composeEnhancers(
    applyMiddleware(logger, ReduxThunk)
  )
);

const persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root')); // Atualize aqui
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </BrowserRouter>
);

serviceWorker.unregister();
