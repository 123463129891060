import {
    ASSISTENCIA_LOGIN,
    ASSISTENCIA_LIMPA_LOGIN,
    ASSISTENCIA_LOADING_ITENS_NOTA
} from './ActionsTypes';

export const LoginAction = (entidade) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOGIN, payload: entidade });
        resolve(entidade);
    });

export const LogoutAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('aqui')
        dispatch({ type: ASSISTENCIA_LIMPA_LOGIN, payload: '' })
        dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
    });