import React, { useState } from 'react';
import Footer from "../../../Footer/Footer";
import NavBar from "../../../Navbar/NavBar";
import { Form, Segment, Button, TextArea, Loader } from 'semantic-ui-react';
import { BrowserView, MobileView } from 'react-device-detect';
import { FormEnviaDadosLuminariasAction } from '../../../../actions/FormActions';
import { connect } from 'react-redux';
import DropzoneComponent from '../formularios/Dropzone'
import { useNavigate } from 'react-router';


function FormEspacoLux(props) {

    const { FormEnviaDadosLuminariasAction } = props;
    const { form_info, loading_form_dados } = props;

    let telefone = form_info?.telefone?.replace(/\s+/g, '');
    let fax = form_info?.fax?.replace(/\s+/g, '');

    const nomeConsumidor = form_info.nomeparc;
    const enderecoConsumidor = form_info.endereco;
    const bairroConsumidor = form_info.bairro;
    const cidadeConsumidor = form_info.cidade;
    const foneConsumidor = telefone?.length === 0 ? (fax?.length === 0 ? '' : fax) : telefone;
    const emailConsumidor = form_info.email;
    const cpfConsumidor = form_info.cgc_cpf;
    const nomeprod = form_info.desc_prod;

    const [formValues, setFormValues] = useState({
        observacoes: '',
    });

    const [errors, setErrors] = useState({});
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [fileError, setFileError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();




    const handleChange = (e, { name, value }) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Se já estiver submetendo, não faça nada
        if (isSubmitting) {
            return;
        }



        let validationErrors = {};

        if (uploadedFiles.length === 0) {
            validationErrors.uploadedFiles = true;
        }

        if (!formValues.observacoes) {
            validationErrors.observacoes = true;
        }

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            // Iniciar o processo de submissão
            setIsSubmitting(true);
            FormEnviaDadosLuminariasAction(form_info, formValues.observacoes, uploadedFiles).then(() => {
                navigate('/assistencia/protocolo');
                setIsSubmitting(false);  // Libera a função para ser chamada novamente após a conclusão
            })

            // Enviar o formulário
            // FormEnviaDadosFerramentasAction({
            //     ...formValues,
            //     uploadedFiles,
            // });
        }
    };
    // console.log('Loading:', loading_form_dados)

    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h3>Assistencia EspaçoLux</h3>
                        <hr></hr>
                        <p style={{ fontSize: '24px' }}>{nomeprod}</p>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    'video/mp4': ['.mp4'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field error={errors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formValues.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formValues.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados === true ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Loader active inline size='tiny' />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>
                    </Segment>


                </Form>
                <Footer />
            </BrowserView>

            <MobileView >
                <div id="topo" />
                <NavBar mobile />
                <Form onSubmit={handleSubmit}>
                    <Segment>
                        <h3>Assistencia EspaçoLux</h3>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Nome</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Nome'
                                    name='nome'
                                    label={nomeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Telefone</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Telefone'
                                    name='telefone'
                                    label={foneConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Email'
                                    name='email'
                                    label={emailConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>CPF</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='CPF'
                                    name='cpf'
                                    label={cpfConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>Endereço</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Endereço'
                                    name='endereco'
                                    label={enderecoConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Bairro</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Bairro'
                                    name='bairro'
                                    label={bairroConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Cidade</label>
                                <Form.Field
                                    fluid="true"
                                    placeholder='Cidade'
                                    name='cidade'
                                    label={cidadeConsumidor}
                                    readOnly
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field error={!!(errors.uploadedFiles || fileError)}>
                            <label>Upload de Imagem</label>
                            <DropzoneComponent
                                maxFiles={5}
                                maxSize={5 * 1024 * 1024} // 5MB
                                accept={{
                                    'image/png': ['.png'],
                                    'image/jpeg': ['.jpeg'],
                                    'image/jpg': ['.jpg'],
                                    'application/pdf': ['.pdf'],
                                    // Adicione outros formatos conforme necessário
                                }}
                                onFilesChange={(files) => {
                                    setUploadedFiles(files);
                                    if (files.length === 0) {
                                        setFileError('Por favor, adicione ao menos um arquivo.');
                                    } else {
                                        setFileError('');
                                    }
                                }}
                            />
                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                        </Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field error={errors.observacoes}>
                                <label>Descreva com detalhes o motivo da solicitação</label>
                                <TextArea
                                    placeholder='Descreva com detalhes o motivo da solicitação'
                                    name='observacoes'
                                    value={formValues.observacoes}
                                    onChange={handleChange}
                                    maxLength={3500}
                                />
                                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }} className="text-left text-gray-500">
                                    {formValues.observacoes.length}/3500
                                </div>
                            </Form.Field>
                        </Form.Group>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button primary type='submit' style={{ marginBottom: '25px', width: '100%', margin: '5px' }} >
                                {loading_form_dados === true ? (
                                    <div style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Loader active inline size='tiny' />
                                    </div>
                                ) : (

                                    <div> Confirmar</div>
                                )}

                            </Button>
                        </div>
                    </Segment>
                </Form>
                <Footer mobile />
            </MobileView>

        </>
    );
}

const mapStateToProps = state => ({
    form_info: state.FormReducer.form_info,
    form_padrao: state.ItensNotaReducer.form_padrao,
    loading_form_dados: state.FormReducer.loading_form_dados
});

export default connect(mapStateToProps, {
    FormEnviaDadosLuminariasAction,
})(FormEspacoLux);