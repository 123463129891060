
function CardEspacoFix() {
    return (
        <div className="card-container">
            <div className='text-block'>
                <h1 className='piso-name'>Ferramentas de Corte</h1>
                <div className='garantia'>
                    <span>Período de Garantia</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>12</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='condicoes-especiais'>
                    Condições Especiais
                </div>
                <div className='descricao-opaca'>
                    <p >
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>

            </div>
            <div className='text-block'>
                <h1 className='piso-name'>Ferramentas de Furo</h1>
                <div className='garantia'>
                    <span>Período de Garantia</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>12</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='condicoes-especiais'>
                    Condições Especiais
                </div>
                <p className='descricao-opaca'>
                    Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                </p>
            </div>
            <div className='text-block'>
                <h1 className='piso-name'>Ferramentas de Desbaste</h1>
                <div className='garantia'>
                    <span>Período de Garantia</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>12</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='condicoes-especiais'>
                    Condições Especiais
                </div>
                <p className='descricao-opaca'>
                    Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                </p>
            </div>
            <div className='text-block'>
                <h1 className='piso-name'>Ferramentas de Utilidades</h1>
                <div className='garantia'>
                    <span>Período de Garantia</span>
                    <div className='garantia-info'>
                        <h1 className='garantia-numero'>2</h1>
                        <span className='garantia-meses'>meses</span>
                    </div>
                </div>
                <hr />
                <div className='condicoes-especiais'>
                    Condições Especiais
                </div>
                <p className='descricao-opaca'>
                    Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                </p>
            </div>
        </div>
    );
}

export default CardEspacoFix;