import React from 'react';
import './CustomDot.css';

const CustomDot = ({ onClick, ...rest }) => {
    const { active } = rest;
    return (
        <li
            className={`custom-dot ${active ? 'active' : ''}`}
            onClick={onClick}
        >
        </li>
    );
};

export default CustomDot;