const api = process.env.REACT_APP_API;

let token = localStorage.token;
if (!token) {
    token = localStorage.token = Math.random().toString(36).substr(-8);
}


const headers = {
    'Accept': 'application/json',
    'Authorization': token
}


export const postLoginAssistencia = (entidade) =>
    fetch(`${api}/assistencia/login/`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFormularioPadrao = (entidade) =>
    fetch(`${api}/assistencia/formulario`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFormularioFerramentas = (entidade) =>
    fetch(`${api}/assistencia/formulario/ferramentas`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFormularioPiso = (entidade) =>
    fetch(`${api}/assistencia/formulario/piso`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFormularioRodapeColaPainelDeck = (entidade) =>
    fetch(`${api}/assistencia/formulario/rodapecolapaineldeck`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postFormularioLuminarias = (entidade) =>
    fetch(`${api}/assistencia/formulario/luminarias`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const postFormularioGenerico = (entidade) =>
    fetch(`${api}/assistencia/formulario/generico`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());

export const postFormularioForroPerfil = (entidade) =>
    fetch(`${api}/assistencia/formulario/forroperfil`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());




export const postAcompanhamentoAssistencia = (entidade) =>
    fetch(`${api}/assistencia/acompanhamento`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


export const postDetalhesProtocoloAssistencia = (entidade) =>
    fetch(`${api}/assistencia/acompanhamento/detalhes`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());



export const postRespostaClienteAssistencia = (entidade) =>
    fetch(`${api}/assistencia/acompanhamento/resposta`, {
        method: 'POST',
        headers: {
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(entidade)
    }).then(res => res.json());


