
import {
    ASSISTENCIA_LOGIN, ASSISTENCIA_LIMPA_LOGIN
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    login: null
}
//eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case ASSISTENCIA_LOGIN:
            return { ...state, login: action.payload }
        case ASSISTENCIA_LIMPA_LOGIN:
            return { login: null }
        default:
            return state; // Retorne o estado atual por padrão
    }

}