import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NavBar from "../../Navbar/NavBar";
import Footer from "../../Footer/Footer";
import { AcompanhamentoAction, DetalhesProtocoloAction } from "../../../actions/AcompanhamentoActions";
import { BrowserView, MobileView } from 'react-device-detect';
import { connect } from 'react-redux';
import AcompanhamentoCardMobile from './AcompanhamentoCardMobile';
import './Acompanhamento.css';

const getStatusClass = (status) => {
    switch (status) {
        case 1:
            return 'status-em-analise';
        case 2:
            return 'status-aguardando-cliente';
        case 3:
            return 'status-aguardando-tecnico';
        case 4:
            return 'status-procedente';
        case 5:
            return 'status-improcedente';
        default:
            return 'status-aguardando-analise';
    }
};

const truncateName = (name, wordLimit) => {
    const words = name.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return name;
};

function Acompanhamento(props) {
    const { itens_nota, acompanhamento, loadingList, loadingDetalhes } = props;
    const { AcompanhamentoAction, DetalhesProtocoloAction } = props;
    //eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const [loadingProtocolo, setLoadingProtocolo] = useState(null);


    useEffect(() => {
        AcompanhamentoAction(itens_nota?.[0].numnota);
        //eslint-disable-next-line
    }, []);

    const navigate = useNavigate();

    const handleRowClick = (protocolo) => {
        if (!loadingDetalhes) {
            setLoadingProtocolo(protocolo);
            DetalhesProtocoloAction(protocolo).then(() => {
                setLoading(true);
                setLoading(false);
                setLoadingProtocolo(null);
                navigate(`/assistencia/acompanhamento/${protocolo}`);
                window.scrollTo(0, 0);

            }).catch(() => {
                setLoading(false);
                setLoadingProtocolo(null);
            });


        }
    };

    const handleAcompanhamento = () => {
        navigate('/assistencia');
    };

    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <div className="acompanhamento-container">
                    <h1>Acompanhamento de Assistência</h1>
                    <div className="button-toolbar">
                        <button className="toolbar-button minimal-button" onClick={handleAcompanhamento}>
                            Voltar
                        </button>
                    </div>
                    {loadingList ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <table className="acompanhamento-table">
                            <thead>
                                <tr>
                                    <th>Nome do Produto</th>
                                    <th>Quantidade</th>
                                    <th>Nota Fiscal</th>
                                    <th>Data de Abertura</th>
                                    <th>Protocolo</th>
                                    <th>Status da Assistência</th>
                                </tr>
                            </thead>
                            {acompanhamento.map((item) => (
                                <tbody key={item.protocolo}>
                                    <tr
                                        onClick={() => handleRowClick(item.protocolo)}
                                        style={{ pointerEvents: loadingDetalhes ? 'none' : 'auto' }}
                                    >
                                        <td>{truncateName(item.produto, 7)}</td>
                                        <td>{item.qntdade}</td>
                                        <td>{item.numnota}</td>
                                        <td>{new Date(item.dtcriacao).toLocaleDateString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })}</td>
                                        <td>{item.protocolo}</td>
                                        <td>
                                            {loadingDetalhes && loadingProtocolo === item.protocolo ? (
                                                <div className="">
                                                    <span className={`loading-spinner-detalhes status-label ${getStatusClass(item.status)}`}>
                                                    </span>
                                                </div>
                                            ) : (

                                                <span className={`status-label ${getStatusClass(item.status)}`}>
                                                    {item.status === 0 ? 'Aguardando Análise' :
                                                        item.status === 1 ? 'Em análise' :
                                                            item.status === 2 ? 'Aguardando Cliente' :
                                                                item.status === 3 ? 'Aguardando Técnico' :
                                                                    item.status === 4 ? 'Procedente' :
                                                                        item.status === 5 ? 'Improcedente' : ''}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                        </table>
                    )}
                </div>
                <Footer />
            </BrowserView>

            <MobileView>
                <div id="topo" />
                <NavBar mobile />
                <div className="acompanhamento-container-mobile">
                    <h1>Acompanhamento de Assistência</h1>
                    <div className="button-toolbar">
                        <button className="toolbar-button minimal-button" onClick={handleAcompanhamento}>
                            Voltar
                        </button>
                    </div>
                    {loadingList ? (
                        <div className="loading-spinner"></div>
                    ) : (
                        <div className="acompanhamento-mobile-card">
                            {acompanhamento.map((item, index) => (
                                <AcompanhamentoCardMobile key={item.numnota || index} data={item} />
                            ))}
                        </div>
                    )}
                </div>
                <Footer mobile />
            </MobileView>
        </>
    );
}

const mapStateToProps = state => ({
    itens_nota: state.ItensNotaReducer.itens_nota,
    acompanhamento: state.AcompanhamentoReducer.acompanhamento,
    loadingList: state.AcompanhamentoReducer.loadingList,
    loadingDetalhes: state.AcompanhamentoReducer.loadingDetalhes,

});

export default connect(mapStateToProps, {
    AcompanhamentoAction,
    DetalhesProtocoloAction
})(Acompanhamento);
