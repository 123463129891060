import './Footer.css';

function Footer(props) {
    return (
        <>
            {props.mobile ? (
                <div className='container-footer-mobile'>
                    <div className='footer-column-mobile'>
                        <h3>ESPAÇOFLOOR</h3>
                        <p>Downloads disponíveis:</p>
                        <ul>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/02/Book-Espa%C3%A7oFloor-2024.pdf' target="_blank">Catálogo</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/09/certificado-floorscore-espacofloor-1.pdf' target="_blank">Certificado FloorScore</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Piso-Laminado.pdf' target="_blank">Certificado de Garantia: Pisos Laminados</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Piso-Vinilico.pdf' target="_blank">Certificado de Garantia: Pisos Vinílicos</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Rodape.pdf' target="_blank">Certificado de Garantia: Rodapés</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/#pisos-vinilicos' target="_blank">Informações Técnicas: Pisos Vinílicos</a></li>
                        </ul>
                    </div>
                    <div className='footer-column-mobile'>
                        <h3>CENTRO DE DISTRIBUIÇÃO</h3>
                        <p>Estrada Luiz Fernando Rodrigues, 2225. Vila Boa vista – Campinas/SP</p>
                        <p>Fone: (019) 3785-9200</p>
                        <p>Seg. a sex. (8h às 18h)</p>
                        <p>Sáb. (8h às 12h)</p>
                    </div>
                    <div className='footer-column-mobile'>
                        <h3>FILIAL CAMPINAS</h3>
                        <p>Av. General Euclides de Figueiredo, 151. Vila Industrial – Campinas / SP – CEP: 13035-506</p>
                        <p>Fone: (019) 3303-7000 / 3232-3451</p>
                        <p>Seg. a sex. (8h às 18h)</p>
                        <p>Sáb. (8h às 12h)</p>
                    </div>
                    <div className='footer-column-mobile'>
                        <h3>PRODUTOS</h3>
                        <ul>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/'>Pisos Laminados</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/'>Pisos Vinílicos</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/rodapes/'>Rodapés</a></li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div className='container-footer'>
                    <div className='footer-column'>
                        <h3>ESPAÇOFLOOR</h3>
                        <p>Downloads disponíveis:</p>
                        <ul>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/02/Book-Espa%C3%A7oFloor-2024.pdf' target="_blank">Catálogo</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/09/certificado-floorscore-espacofloor-1.pdf' target="_blank">Certificado FloorScore</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Piso-Laminado.pdf' target="_blank">Certificado de Garantia: Pisos Laminados</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Piso-Vinilico.pdf' target="_blank">Certificado de Garantia: Pisos Vinílicos</a></li>
                            <li><a href='https://www.espacofloor.com.br/wp-content/uploads/2024/06/Certificado-do-Rodape.pdf' target="_blank">Certificado de Garantia: Rodapés</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/#pisos-vinilicos' target="_blank">Informações Técnicas: Pisos Vinílicos</a></li>
                        </ul>
                    </div>
                    <div className='footer-column'>
                        <h3>CENTRO DE DISTRIBUIÇÃO</h3>
                        <p>Estrada Luiz Fernando Rodrigues, 2225. Vila Boa vista – Campinas/SP</p>
                        <p>Fone: (019) 3785-9200</p>
                        <p>Seg. a sex. (8h às 18h)</p>
                        <p>Sáb. (8h às 12h)</p>
                    </div>
                    <div className='footer-column'>
                        <h3>FILIAL CAMPINAS</h3>
                        <p>Av. General Euclides de Figueiredo, 151. Vila Industrial – Campinas / SP – CEP: 13035-506</p>
                        <p>Fone: (019) 3303-7000 / 3232-3451</p>
                        <p>Seg. a sex. (8h às 18h)</p>
                        <p>Sáb. (8h às 12h)</p>
                    </div>
                    <div className='footer-column'>
                        <h3>PRODUTOS</h3>
                        <ul>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/'>Pisos Laminados</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/pisos/'>Pisos Vinílicos</a></li>
                            <li><a href='https://www.espacofloor.com.br/espacofloor/rodapes/'>Rodapés</a></li>
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}

export default Footer;
