import { combineReducers } from 'redux';
import ItensNotaReducer from './ItensNotaReducer';
import LoginReducer from './LoginReducer';
import FormReducer from './FormReducer';
import AcompanhamentoReducer from './AcompanhamentoReducer';


export default combineReducers({
    LoginReducer,
    ItensNotaReducer,
    FormReducer,
    AcompanhamentoReducer
})