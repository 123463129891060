export const ASSISTENCIA_LOGIN = "ASSISTENCIA_LOGIN";
export const ASSISTENCIA_ITENS_NOTA = "ASSISTENCIA_ITENS_NOTA";
export const ASSISTENCIA_LOADING_ITENS_NOTA = "ASSISTENCIA_LOADING_ITENS_NOTA";
export const ASSISTENCIA_FORM_SELECIONADO = "ASSISTENCIA_FORM_SELECIONADO";
export const ASSISTENCIA_FORM_PRE_DADOS = "ASSISTENCIA_FORM_PRE_DADOS";
export const ASSISTENCIA_FORM_DADOS = "ASSISTENCIA_FORM_DADOS";
export const ASSISTENCIA_LOADING_FORM_DADOS = "ASSISTENCIA_LOADING_FORM_DADOS";
export const ASSISTENCIA_LIMPA_LOGIN = "ASSISTENCIA_LIMPA_LOGIN";
export const ASSISTENCIA_FORM_PADRAO_DADOS = "ASSISTENCIA_FORM_PADRAO_DADOS";
export const ASSISTENCIA_ACOMPANHAMENTO = "ASSISTENCIA_ACOMPANHAMENTO";
export const ASSISTENCIA_DETALHES_PROTOCOLO = "ASSISTENCIA_DETALHES_PROTOCOLO";
export const ASSISTENCIA_ACOMPANHAMENTO_LOADING = "ASSISTENCIA_ACOMPANHAMENTO_LOADING";
export const ASSISTENCIA_DETALHES_LOADING = "ASSISTENCIA_DETALHES_LOADING";
export const ASSISTENCIA_RESPOSTA_LOADING = "ASSISTENCIA_RESPOSTA_LOADING";

