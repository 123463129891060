import {
    ASSISTENCIA_FORM_DADOS,
    ASSISTENCIA_FORM_PRE_DADOS, ASSISTENCIA_LOADING_FORM_DADOS, ASSISTENCIA_FORM_PADRAO_DADOS
} from './ActionsTypes';

import * as Api from '../utils/API';

export const FormPreDadosAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_FORM_PRE_DADOS, payload: entidade })
        resolve(entidade)
    });



// Função para converter ArrayBuffer em Base64
function arrayBufferToBase64(buffer) {
    const binary = [];
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary.push(String.fromCharCode(bytes[i]));
    }
    return btoa(binary.join(''));
}
//============================= ##ENVIAR DADOS DO FORMULARIO DE FERRAMENTAS##  ========================//
export const FormEnviaDadosFerramentaAction = (padrao, observacao, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, observacao, arrayBase64, resolvedBinaryFiles];


                Api.postFormularioFerramentas(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });
//=============================FIM ##ENVIAR DADOS DO FORMULARIO DE FERRAMENTAS##  FIM========================//


export const FormPadraoDadosAction = (entidade) => (dispatch) =>

    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_FORM_PADRAO_DADOS, payload: entidade })
        resolve(entidade)
    });




//=============================ENVIAR DADOS DO FORMULARIO DE PISO========================

export const FormEnviaDadosPisoAction = (padrao, dados, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, dados, arrayBase64, resolvedBinaryFiles];

                console.log(updatedCombinedData);

                Api.postFormularioPiso(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });

//=============================FIM ##ENVIAR DADOS DO FORMULARIO DE PISO## FIM ========================//










//===========================ENVIA DADOS FORMULARIO RODAPE/COLA/PAINEL/DECK=======================//
export const FormEnviaDadosPainelColaRodapeDeckAction = (padrao, observacao, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, observacao, arrayBase64, resolvedBinaryFiles];

                console.log(updatedCombinedData);

                Api.postFormularioRodapeColaPainelDeck(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });
//===========================FIM ##ENVIA DADOS FORMULARIO RODAPE/COLA/PAINEL/DECK## FIM=======================//






//============================= ##ENVIAR DADOS DO FORMULARIO DE FERRAMENTAS##  ========================//
export const FormEnviaDadosLuminariasAction = (padrao, observacao, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, observacao, arrayBase64, resolvedBinaryFiles];


                Api.postFormularioLuminarias(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });
//=============================FIM ##ENVIAR DADOS DO FORMULARIO DE LUMINARIAS##  FIM========================//





//============================= ##ENVIAR DADOS DO FORMULARIO DE GENERICO##  ========================//
export const FormEnviaDadosGenericoAction = (padrao, observacao, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, observacao, arrayBase64, resolvedBinaryFiles];


                Api.postFormularioGenerico(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });
//=============================FIM ##ENVIAR DADOS DO FORMULARIO DE GENERICO##  FIM========================//







//============================= ##ENVIAR DADOS DO FORMULARIO DE GENERICO##  ========================//
export const FormEnviaDadosForroPerfilAction = (padrao, observacao, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: true });
        console.log(files)
        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [padrao, observacao, arrayBase64, resolvedBinaryFiles];


                Api.postFormularioForroPerfil(updatedCombinedData)
                    .then(dados => {
                        dispatch({ type: ASSISTENCIA_FORM_DADOS, payload: dados });
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        resolve(dados);
                    })
                    .catch(error => {
                        console.log('DEU ERRO ->', error);
                        dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                        reject(error);
                    });
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);
                dispatch({ type: ASSISTENCIA_LOADING_FORM_DADOS, payload: false });
                reject(error);
            });
    });
//=============================FIM ##ENVIAR DADOS DO FORMULARIO DE GENERICO##  FIM========================//