import "./CardEspacoFloor.css"

function CardEspacoFloor() {
    return (
        <>
            <div className="card-container">
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>12</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <div className='descricao-opaca'>
                        <p >
                            Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                        </p>
                    </div>

                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Soft</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>12</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Royal</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>5</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Solid Plank Easy</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>12</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Office</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>6</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Loose Lay</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>2</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
                <div className='text-block'>
                    <h1 className='piso-name'>Piso Vinílico Pietra</h1>
                    <div className='garantia'>
                        <span>Período de Garantia</span>
                        <div className='garantia-info'>
                            <h1 className='garantia-numero'>7</h1>
                            <span className='garantia-meses'>meses</span>
                        </div>
                    </div>
                    <hr />
                    <div className='condicoes-especiais'>
                        Condições Especiais
                    </div>
                    <p className='descricao-opaca'>
                        Por favor, baixe as políticas de garantia no link abaixo para visualizar mais detalhes.
                    </p>
                </div>
            </div>
        </>
    );
}

export default CardEspacoFloor;
