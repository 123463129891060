// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home/Home.js'; // Ajuste o caminho se necessário
import Assistencia from './components/pages/Assistencia/Assistencia';
// import FormularioPadrao from './components/pages/Assistencia/formularios/FormPadrao.js';
import FormEspacoForroPerfil from './components/pages/Assistencia/formularios/FormEspacoForroPerfil.js';
import FormEspacoLux from './components/pages/Assistencia/formularios/FormEspacoLux.js';
import FormFerramentas from './components/pages/Assistencia/formularios/FormFerramentas.js';
import FormPiso from './components/pages/Assistencia/formularios/FormPiso.js';
import FormRodapeColaPainelDeck from './components/pages/Assistencia/formularios/FormRodapeColaPainelDeck.js';
import FormGenerico from './components/pages/Assistencia/formularios/FormGenerico.js';
import ProtocoloCriado from './components/pages/protocolo/ProtocoloCriado.js';
import Acompanhamento from './components/pages/AcompanhamentoProtocolo/Acompanhamento.js';

import DetalhesProtocolo from './components/pages/AcompanhamentoProtocolo/DetalhesProtocolo.js'; // Novo componente para detalhes do protocolo
import ScrollToTop from './ScrollToTop.js';

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/assistencia" element={<Assistencia />} />
        {/* <Route path="/assistencia/formulario" element={<FormularioPadrao />} /> */}
        <Route path="/assistencia/formulario/ForroPerfil" element={<FormEspacoForroPerfil />} />
        <Route path="/assistencia/formulario/Lux" element={<FormEspacoLux />} />
        <Route path="/assistencia/formulario/Ferramentas" element={<FormFerramentas />} />
        <Route path="/assistencia/formulario/Piso" element={<FormPiso />} />
        <Route path="/assistencia/formulario/Rodape/Cola/Painel/Deck" element={<FormRodapeColaPainelDeck />} />
        <Route path="/assistencia/protocolo" element={< ProtocoloCriado />} />
        <Route path="/assistencia/formulario/produto" element={< FormGenerico />} />
        <Route path="/assistencia/acompanhamento" element={< Acompanhamento />} />
        <Route path="/assistencia/acompanhamento/:numeroProtocolo" element={<DetalhesProtocolo />} />

      </Routes>
    </>
  );
}

export default App;
