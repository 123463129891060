import React from 'react';
import NavBar from "../../Navbar/NavBar";
import ProductTable from "./ProductTable";
import { connect } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import "./Assistencia.css";
import Footer from "../../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { ImExit } from "react-icons/im";
import { LogoutAction } from "../../../actions/LoginActions";
import { LimpaListItemAction } from "../../../actions/ListItensActions";
import { BrowserView, MobileView } from 'react-device-detect';
import ProductCardMobile from "./ProductCardMobile";

function Assistencia(props) {

    const { itens_nota } = props;

    const navigate = useNavigate(); // Hook para navegação
    const { LogoutAction, LimpaListItemAction } = props;

    // Use navigate para navegação programática, se necessário

    const handleLogout = () => {
        LimpaListItemAction()
        LogoutAction();
        navigate('/');
    }
    const handleAcompanhamento = () => {
        navigate('/assistencia/acompanhamento');
    };


    return (
        <>
            <BrowserView>
                <div id="topo" />
                <NavBar />
                <div className="assistencia-page">
                    <div className="greeting-container">
                        <h1>Olá, {itens_nota?.[0].nomeparc}</h1>
                        <h2>Número da Nota</h2>
                        <h1>{itens_nota?.[0].numnota}</h1>
                    </div>
                    {/* Barra de botões minimalista */}
                    <div className="button-toolbar">
                        <button className="toolbar-button minimal-button" onClick={handleLogout}>
                            Sair <ImExit fontSize={16} />
                        </button>
                        <button className="toolbar-button minimal-button" onClick={handleAcompanhamento}>
                            Acompanhar assistências
                        </button>
                    </div>
                    {/* Conteúdo da tabela */}
                    <div className="assistencia-container">
                        <ProductTable data={itens_nota} />
                    </div>
                </div>
                <Footer />
            </BrowserView>

            {/* ------------------------------------ SEPARAÇÃO BROWSER / MOBILE --------------------------------------------- */}

            <MobileView>

                <div id="topo" />
                <NavBar mobile />

                <div className="greeting-container">
                    <h1>Olá, {itens_nota?.[0].nomeparc}</h1>
                    <h2>Número da Nota</h2>
                    <h1 >{itens_nota?.[0].numnota}</h1>
                </div>
                <div className="button-toolbar">
                    <button className="toolbar-button minimal-button" onClick={handleLogout}>
                        Sair <ImExit fontSize={16} />
                    </button>
                    <button className="toolbar-button minimal-button" onClick={handleAcompanhamento}>
                        Acompanhar assistências
                    </button>
                </div>
                <div className="assistencia-container-mobile">
                    <h4>Produtos da nota:</h4>
                    {itens_nota.map((item, index) => (
                        <ProductCardMobile key={item.numnota || index} data={item} />
                    ))}
                </div>
                <Footer mobile />
            </MobileView>

        </>
    );
}

const mapStateToProps = state => ({
    itens_nota: state.ItensNotaReducer.itens_nota
});

export default connect(mapStateToProps, {
    LogoutAction,
    LimpaListItemAction
})(Assistencia);
