import React from "react";
import { useNavigate } from "react-router-dom";
import { FaExclamationTriangle } from "react-icons/fa";
import { connect } from 'react-redux';
import { FormPreDadosAction } from '../../../actions/FormActions.js';
import "./ProductTable.css";  // Importando o arquivo CSS

function ProductTable(props) {
    const { data } = props;
    const { FormPreDadosAction } = props;
    const navigate = useNavigate();

    const handleOpenAssistance = (product) => {

        let categoria = product?.categoria;
        let primeiraPalavra = categoria?.split(' ')[0];

        console.log(primeiraPalavra)
        if (primeiraPalavra == 'PISOS' || primeiraPalavra == 'PISO') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Piso');
            });
        } else if (product.marca == 'ESPAÇOFIX') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Ferramentas');
            });
        } else if (product.marca == 'ESPAÇOLUX') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Lux');
            });
        } else if (primeiraPalavra == 'FORROS' || primeiraPalavra == 'FORRO' || primeiraPalavra == 'PERFIS' || primeiraPalavra == 'PERFIL') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/ForroPerfil');
            });
        } else if (primeiraPalavra == 'COLAS' || primeiraPalavra == 'COLA' || primeiraPalavra == 'RODAPES' || primeiraPalavra == 'RODAPE' || primeiraPalavra == 'PAINEL' || primeiraPalavra == 'DECKS' || primeiraPalavra == 'DECK') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Rodape/Cola/Painel/Deck');
            });
        } else {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Rodape/Cola/Painel/Deck');
            });
        }

    };


    return (
        <>

            <table className="product-table">
                <thead>
                    <tr>
                        <th>Descrição do produto</th>
                        <th>Quantidade</th>
                        <th>Valor unitário</th>
                        <th>Fabricante</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((product, index) => (
                        <tr key={index}>
                            <td>{product.desc_prod}</td>
                            <td>{product.qntdade}</td>
                            <td>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.vlr_unit)}</td>
                            <td>{product.fabricante}</td>
                            <td>
                                {product.fabricante.includes('Espaço') ? (
                                    <button
                                        className="assistencia-button"
                                        onClick={() => handleOpenAssistance(product)}
                                    >
                                        Abrir Assistência
                                    </button>
                                ) : (
                                    <div className="alert-container">
                                        <FaExclamationTriangle className="alert-icon" />
                                        <span className="alert-text">Abrir ticket no site da fabricante</span>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

const mapStateToProps = state => ({
    form_info: state.FormReducer.form_info
});

export default connect(mapStateToProps, {
    FormPreDadosAction,
})(ProductTable);
