import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate
import Navbar from '../../Navbar/NavBar.js';
import Carousel from "react-multi-carousel";
import CarouselImg1 from "../../../img/carrosel-1.jpg";
import CarouselImg2 from "../../../img/carrosel-2.jpg";
import CarouselImg3 from "../../../img/carrosel-3.jpg";
import CarouselImg4 from "../../../img/carrosel-4.jpg";
import CarouselImg5 from "../../../img/carrosel-5.jpg";
import CarouselImg1MBL from "../../../img/carrosel-1-mobile.jpg";
import CarouselImg2MBL from "../../../img/carrosel-2-mobile.jpg";
import CarouselImg3MBL from "../../../img/carrosel-3-mobile.jpg";
import CarouselImg4MBL from "../../../img/carrosel-4-mobile.jpg";
import CarouselImg5MBL from "../../../img/carrosel-5-mobile.jpg";
import CardEspacoFloor from './CardsGarantia/CardEspacoFloor';
import CardEspacoWall from './CardsGarantia/CardEspacoWall';
import CardEspacoForro from './CardsGarantia/CardEspacoForro';
import CardEspacoFix from './CardsGarantia/CardEspacoFix';
import CardEspacoLux from './CardsGarantia/CardEspacoLux';
import "react-multi-carousel/lib/styles.css";
import "./Home.css";
import CustomDot from './CustonDot.js';
import { connect } from 'react-redux';
import { ItensNotaAction } from '../../../actions/ListItensActions.js';
import { DetalhesProtocoloAction } from '../../../actions/AcompanhamentoActions.js';
import { LogoutAction } from "../../../actions/LoginActions";
import ModalLogin from './ModalLogin.js';
import Footer from '../../Footer/Footer.js';
import { BrowserView, MobileView } from 'react-device-detect';

function Home(props) {
    const [selectedCard, setSelectedCard] = useState('EspaçoFloor');
    const [openModal, setOpenModal] = useState(false);
    const [isProtoloco, setIsProtocolo] = useState(false);
    const [modalData, setModalData] = useState({
        cpf: '',
        nota: '',
        protocolo: ''
    });
    const [isErrorCpf, setIsErrorCpf] = useState(false);
    const [isErrorNota, setIsErrorNota] = useState(false);
    const [isErrorProtocolo, setIsErrorProtocolo] = useState(false);
    const [isErrorNotFound, setIsErrorNotFound] = useState(false);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const containerRef = useRef(null);
    //eslint-disable-next-line
    const { login, loading_itens } = props;

    const navigate = useNavigate(); // Hook para navegação
    const { ItensNotaAction, DetalhesProtocoloAction, LogoutAction } = props;

    const handleButtonClickCard = (cardType) => {
        setSelectedCard(cardType);
        setToggleDropdown(!toggleDropdown)
    };

    useEffect(() => {
        // console.log('LOGIN ->', login)
        const cards = document.querySelectorAll('.text-block');
        cards.forEach(card => {
            card.classList.add('fade-in');
        });
    }, [selectedCard]);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const onOpenPopup = () => {
        setOpenModal(true);
    };

    const onClosePopup = () => {
        setOpenModal(false);
        setIsProtocolo(false);
        setModalData({
            cpf: '',
            nota: '',
            protocolo: '',
        });
        setIsErrorCpf(false);
        setIsErrorNota(false);
        setIsErrorProtocolo(false);
        LogoutAction()
    };

    const handleIsProtocolo = () => {
        setIsProtocolo(!isProtoloco);
        setModalData({
            cpf: '',
            nota: '',
            protocolo: '',
        });
        setIsErrorCpf(false);
        setIsErrorNota(false);
        setIsErrorProtocolo(false);
        setIsErrorNotFound(null);
    };

    const handleModalSubmit = () => {
        // Aqui você pode adicionar a lógica para manipular os dados do modal, se necessário
        // console.log(dadosAssistencia);


        let cpf_cnpj = modalData.cpf;
        cpf_cnpj = cpf_cnpj.replace(/[.-]/g, '');
        let nunota = modalData.nota
        nunota = nunota.replace(/[.-]/g, '');


        console.log(nunota);
        console.log(cpf_cnpj)

        if (cpf_cnpj.length < 11 && nunota.length <= 0 && isProtoloco === false) {
            setIsErrorCpf(true)
            setIsErrorNota(true)
        } else if (cpf_cnpj.length < 11 && isProtoloco === false) {

            setIsErrorCpf(true)
            setIsErrorNota(false)

        } else if (nunota.length <= 0 && isProtoloco === false) {
            setIsErrorNota(true);
            setIsErrorCpf(false)
        }
        else if (modalData.protocolo.length <= 0 && isProtoloco === true) {
            setIsErrorProtocolo(true);
        } else {
            setIsErrorCpf(false)
            setIsErrorNota(false)
            setIsErrorProtocolo(false);
            setIsErrorNotFound(null);

            // LoginAction(modalData);
            console.log(modalData)
            if (modalData.protocolo.length > 0) {
                DetalhesProtocoloAction(modalData.protocolo).then((dados) => {
                    if (dados.DataSource1[0].status === 'Erro') {
                        setIsErrorNotFound(dados.DataSource1);
                    } else {
                        navigate(`/assistencia/acompanhamento/${modalData.protocolo}`);
                    }

                })
            } else {
                ItensNotaAction(modalData).then((dados) => {
                    if (dados[0].status === 'Erro') {
                        setIsErrorNotFound(dados)
                    } else {
                        navigate('/assistencia');

                    }
                });
            }
        }

    };


    const handleMouseDown = (e) => {
        const container = containerRef.current;
        container.isDown = true;
        container.startX = e.pageX - container.offsetLeft;
        container.scrollLeftStart = container.scrollLeft;
    };

    const handleMouseLeave = () => {
        const container = containerRef.current;
        container.isDown = false;
    };

    const handleMouseUp = () => {
        const container = containerRef.current;
        container.isDown = false;
    };

    const handleMouseMove = (e) => {
        const container = containerRef.current;
        if (!container.isDown) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = (x - container.startX) * 1; // Ajuste a velocidade de rolagem aqui
        container.scrollLeft = container.scrollLeftStart - walk;
    };

    const handleInputChange = (fieldName, value) => {
        setModalData({
            ...modalData,
            [fieldName]: value
        });
        setIsErrorNotFound(null);
    };


    return (
        <>
            <BrowserView>
                <div id="topo" />
                <ModalLogin
                    onClosePopup={onClosePopup}
                    openModal={openModal}
                    isProtoloco={isProtoloco}
                    isErrorCpf={isErrorCpf}
                    modalData={modalData}
                    isErrorNota={isErrorNota}
                    handleIsProtocolo={handleIsProtocolo}
                    isErrorProtocolo={isErrorProtocolo}
                    handleModalSubmit={handleModalSubmit}
                    handleInputChange={handleInputChange}
                    isErrorNotFound={isErrorNotFound}
                    loading={loading_itens} />
                <Navbar />
                <div className="container-infos">
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        autoPlay={true}
                        customDot={<CustomDot />}
                    >
                        <div>
                            <img src={CarouselImg1} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg2} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg3} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg4} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg5} alt='#' className='contract' />
                        </div>
                    </Carousel>
                </div>

                <div>
                    <div className='container-marcas'>
                        <button onClick={() => handleButtonClickCard('EspaçoFloor')}>EspaçoFloor</button>
                        <button onClick={() => handleButtonClickCard('EspaçoWall')}>EspaçoWall</button>
                        <button onClick={() => handleButtonClickCard('EspaçoForro')}>EspaçoForro</button>
                        <button onClick={() => handleButtonClickCard('EspaçoFix')}>EspaçoFix</button>
                        <button onClick={() => handleButtonClickCard('EspaçoLux')}>EspaçoLux</button>
                    </div>

                    <div
                        ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        className='info-cards-container'>
                        {selectedCard === 'EspaçoFloor' && <CardEspacoFloor />}
                        {selectedCard === 'EspaçoWall' && <CardEspacoWall />}
                        {selectedCard === 'EspaçoForro' && <CardEspacoForro />}
                        {selectedCard === 'EspaçoFix' && <CardEspacoFix />}
                        {selectedCard === 'EspaçoLux' && <CardEspacoLux />}
                    </div>
                </div>

                <div className='container-know-more'>
                    <h1 className='title-know-more'>Saiba mais sobre nossas políticas</h1>
                    <div className='text-know-more'>
                        <h3 className='subtitle-know-more'>Garantia de Qualidade</h3>
                        <span>Parabéns por adquirir um dos produtos da Família EspaçoFloor. Sejam os pisos, forros, ripados, ferramentas ou luminárias, todas as nossas linhas de produtos seguem padrões internacionais de qualidade e design. Porém, se algum imprevisto ocorrer, nesta página você encontrará mais informações sobre manutenção, garantia e assistência técnica. Conte conosco para cuidar do seu espaço!</span>
                    </div>
                    <div className="quadro-container">
                        <div className="quadro-1">
                            <h2>Princípios Básicos da Garantia</h2>
                            <p> A garantia cobre totalmente defeitos de fabricação, desde que o produto seja instalado e utilizado conforme as especificações do manual, em ambientes adequados e dentro das suas capacidades.</p>
                        </div>
                        <div className="quadro-2">
                            <h1>EspaçoFloor</h1>
                            <p>Os principais problemas em pisos são decorrentes da má preparação do contra-piso e da sua instalação incorreta. Atenção, a garantia não cobre danos por umidade, uso inadequado, instalação em ambientes não indicados ou exposição excessiva ao calor e à luz solar.</p>
                        </div>
                        <div className="quadro-3">
                            <h1>EspaçoForro</h1>
                            <p>Cada tipo de forro possui características próprias, e por isso precisa de acessórios específicos. Utilize guias, perfis, parafusos e acessórios próprios para cada tipo de forro.
                            </p>
                        </div>
                    </div>
                    <div className="quadro-container">
                        <div className="quadro-1">
                            <h1>EspaçoWall</h1>
                            <p>Por existirem diferentes revestimentos para ambientes internos ou externos, é fundamental utilizar acessórios adequados. As lastras marmorizadas, por exemplo, devido ao tamanho das placas, é altamente recomendado utilizar cola específica para suportar o peso.</p>
                        </div>
                        <div className="quadro-2">
                            <h1>EspaçoFix</h1>
                            <p>Para ferramentas elétricas, é indispensável atentar-se à voltagem do equipamento. A garantia não cobre danos por mal uso, impactos ou quedas, danos elétricos por erro de voltagem, exposição à água ou uso de bateria inadequada. Sempre leia o manual antes de começar a utilizar a ferramenta.</p>
                        </div>
                        <div className="quadro-3">
                            <h1>EspaçoLux</h1>
                            <p>Assim como todo projeto elétrico, a instalação da iluminação requer fiação e dimensionamento adequados dos componentes. Conte com a ajuda de um profissional. Para instalação elétrica, é indispensável atentar-se à potência do equipamento e da rede.</p>
                        </div>
                    </div>
                </div>

                <div className='container-sol-asdsitencia'>
                    <div className='container-title'>
                        <h1>Solicitar Assistência</h1>
                    </div>
                    <div className='container-sol-asdsitencia-2'>
                        <div className='container-text-sol'>
                            <h2>Solicitação.</h2>
                            <p>Clique aqui para iniciar o processo de garantia do seu produto. Você será guiado passo a passo e poderá acompanhar a análise do seu pedido.</p>
                            <div className='container-button'>
                                <button onClick={onOpenPopup}>Solicitar Assistência</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </BrowserView>


            {/* ------------------------------------ SEPARAÇÃO BROWSER / MOBILE --------------------------------------------- */}

            <div className='container-menu' />

            <MobileView>
                <div id="topo" />
                <ModalLogin
                    onClosePopup={onClosePopup}
                    openModal={openModal}
                    isProtoloco={isProtoloco}
                    isErrorCpf={isErrorCpf}
                    modalData={modalData}
                    isErrorNota={isErrorNota}
                    handleIsProtocolo={handleIsProtocolo}
                    isErrorProtocolo={isErrorProtocolo}
                    handleModalSubmit={handleModalSubmit}
                    handleInputChange={handleInputChange}
                    isErrorNotFound={isErrorNotFound}
                    loading={loading_itens} />
                <Navbar mobile />
                <div className="container-infos-mobile">
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={true}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px"
                        autoPlay={true}
                        customDot={<CustomDot />}
                    >
                        <div>
                            <img src={CarouselImg1MBL} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg2MBL} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg3MBL} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg4MBL} alt='#' className='contract' />
                        </div>
                        <div>
                            <img src={CarouselImg5MBL} alt='#' className='contract' />
                        </div>
                    </Carousel>
                </div>

                <div>
                    <div className='container-marcas-mobile'>
                        <button onClick={() => setToggleDropdown(!toggleDropdown)}>
                            <div className='icon-menu-mobile icon-container'>
                                <h3 style={{ color: '#000000' }}>{selectedCard}</h3>
                            </div>
                        </button>
                        <div className={`dropdown-icon ${toggleDropdown ? 'open' : ''} `}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    {toggleDropdown && (
                        <div className='dropdown'>
                            <ul>
                                {selectedCard !== 'EspaçoFloor' && (<div className='container-marcas-mobile-li'>
                                    <button onClick={() => handleButtonClickCard('EspaçoFloor')}><h3 style={{ color: '#000000' }}>EspaçoFloor</h3></button>
                                </div>)}
                                {selectedCard !== 'EspaçoWall' && (<div className='container-marcas-mobile-li'>
                                    <button onClick={() => handleButtonClickCard('EspaçoWall')}><h3 style={{ color: '#000000' }}>EspaçoWall</h3></button>
                                </div>)}
                                {selectedCard !== 'EspaçoForro' && (<div className='container-marcas-mobile-li'>
                                    <button onClick={() => handleButtonClickCard('EspaçoForro')}><h3 style={{ color: '#000000' }}>EspaçoForro</h3></button>
                                </div>)}
                                {selectedCard !== 'EspaçoFix' && (<div className='container-marcas-mobile-li'>
                                    <button onClick={() => handleButtonClickCard('EspaçoFix')}><h3 style={{ color: '#000000' }}>EspaçoFix</h3></button>
                                </div>)}
                                {selectedCard !== 'EspaçoLux' && (<div className='container-marcas-mobile-li'>
                                    <button onClick={() => handleButtonClickCard('EspaçoLux')}><h3 style={{ color: '#000000' }}>EspaçoLux</h3></button>
                                </div>)}
                            </ul>

                        </div>
                    )}

                    <div
                        ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onMouseUp={handleMouseUp}
                        onMouseMove={handleMouseMove}
                        className='info-cards-container'>
                        {selectedCard === 'EspaçoFloor' && <CardEspacoFloor />}
                        {selectedCard === 'EspaçoWall' && <CardEspacoWall />}
                        {selectedCard === 'EspaçoForro' && <CardEspacoForro />}
                        {selectedCard === 'EspaçoFix' && <CardEspacoFix />}
                        {selectedCard === 'EspaçoLux' && <CardEspacoLux />}
                    </div>
                </div>

                <div className='container-know-more-mobile'>
                    <h1 className='title-know-more-mobile'>Saiba mais sobre nossas políticas</h1>
                    <div className='text-know-more-mobile'>
                        <h3 className='subtitle-know-more'>Garantia de Qualidade</h3>
                        <span>Parabéns por adquirir um dos produtos da Família EspaçoFloor. Sejam os pisos, forros, ripados, ferramentas ou luminárias, todas as nossas linhas de produtos seguem padrões internacionais de qualidade e design. Porém, se algum imprevisto ocorrer, nesta página você encontrará mais informações sobre manutenção, garantia e assistência técnica. Conte conosco para cuidar do seu espaço!</span>
                    </div>
                    <div className="quadro-container">
                        <div className="quadro-1">
                            <h1>Princípios Básicos da Garantia</h1>
                            <p> A garantia cobre totalmente defeitos de fabricação, desde que o produto seja instalado e utilizado conforme as especificações do manual, em ambientes adequados e dentro das suas capacidades.</p>                        </div>
                        <div className="quadro-2">
                            <h1>EspaçoFloor</h1>
                            <p>Os principais problemas em pisos são decorrentes da má preparação do contra-piso e da sua instalação incorreta. Atenção, a garantia não cobre danos por umidade, uso inadequado, instalação em ambientes não indicados ou exposição excessiva ao calor e à luz solar.</p>                        </div>
                        <div className="quadro-3">
                            <h1>EspaçoForro</h1>
                            <p>Cada tipo de forro possui características próprias, e por isso precisa de acessórios específicos. Utilize guias, perfis, parafusos e acessórios próprios para cada tipo de forro.
                            </p>
                        </div>
                        <div className="quadro-1">
                            <h1>EspaçoWall</h1>
                            <p>Por existirem diferentes revestimentos para ambientes internos ou externos, é fundamental utilizar acessórios adequados. As lastras marmorizadas, por exemplo, devido ao tamanho das placas, é altamente recomendado utilizar cola específica para suportar o peso.</p>                        </div>
                        <div className="quadro-2">
                            <h1>EspaçoFix</h1>
                            <p>Para ferramentas elétricas, é indispensável atentar-se à voltagem do equipamento. A garantia não cobre danos por mal uso, impactos ou quedas, danos elétricos por erro de voltagem, exposição à água ou uso de bateria inadequada. Sempre leia o manual antes de começar a utilizar a ferramenta.</p>                        </div>
                        <div className="quadro-3">
                            <h1>EspaçoLux</h1>
                            <p>Assim como todo projeto elétrico, a instalação da iluminação requer fiação e dimensionamento adequados dos componentes. Conte com a ajuda de um profissional. Para instalação elétrica, é indispensável atentar-se à potência do equipamento e da rede.</p>                        </div>
                    </div>
                </div>

                <div className='container-sol-asdsitencia-mobile'>
                    <div className='container-title'>
                        <h1>Solicitar Assistência</h1>
                    </div>
                    <div className='container-sol-asdsitencia-2'>
                        <div className='container-text-sol-mobile'>
                            <h2>Solicitação.</h2>
                            <p>Clique aqui para iniciar o processo de garantia do seu produto. Você será guiado passo a passo e poderá acompanhar a análise do seu pedido.</p>
                            <div className='container-button'>
                                <button onClick={onOpenPopup}>Solicitar Assistência</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer mobile />

            </MobileView>
        </>
    );
}

const mapStateToProps = state => ({
    itens_nota: state.ItensNotaReducer.itens_nota,
    login: state.LoginReducer.login,
    loading_itens: state.ItensNotaReducer.loading_itens
});

export default connect(mapStateToProps, {
    ItensNotaAction,
    DetalhesProtocoloAction,
    LogoutAction
})(Home);
