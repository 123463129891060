import {
    ASSISTENCIA_ACOMPANHAMENTO,
    ASSISTENCIA_DETALHES_PROTOCOLO,
    ASSISTENCIA_ACOMPANHAMENTO_LOADING,
    ASSISTENCIA_DETALHES_LOADING,
    ASSISTENCIA_RESPOSTA_LOADING,
    ASSISTENCIA_LOADING_ITENS_NOTA
} from './ActionsTypes';

import * as Api from '../utils/API';

export const AcompanhamentoAction = (entidade) => (dispatch) =>
    new Promise((resolve, reject) => {
        const dados = { acompanhamento: entidade }

        dispatch({ type: ASSISTENCIA_ACOMPANHAMENTO_LOADING, payload: true })

        Api.postAcompanhamentoAssistencia(dados).then(dados => {
            dispatch({ type: ASSISTENCIA_ACOMPANHAMENTO, payload: dados })
            dispatch({ type: ASSISTENCIA_ACOMPANHAMENTO_LOADING, payload: false })
            resolve([])
        })
    });


export const DetalhesProtocoloAction = (entidade) => (dispatch) =>
    new Promise((resolve, reject) => {
        const entidadeSemEspacos = entidade.replace(/\s+/g, '');

        const dados = { protocolo: entidadeSemEspacos }
        dispatch({ type: ASSISTENCIA_DETALHES_LOADING, payload: true })
        dispatch({ type: ASSISTENCIA_RESPOSTA_LOADING, payload: false })
        dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: true })
        Api.postDetalhesProtocoloAssistencia(dados).then(dados => {
            dispatch({ type: ASSISTENCIA_DETALHES_PROTOCOLO, payload: dados })
            dispatch({ type: ASSISTENCIA_DETALHES_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
            resolve(dados)
        })
    });

export const DetalhesProtocoloClearAction = (entidade) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_DETALHES_PROTOCOLO, payload: [] })
    });

// Função para converter ArrayBuffer em Base64
function arrayBufferToBase64(buffer) {
    const binary = [];
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary.push(String.fromCharCode(bytes[i]));
    }
    return btoa(binary.join(''));
}


export const RespostaClienteAction = (entidade, files) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_RESPOSTA_LOADING, payload: true })


        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        const binaryFiles = Promise.all(
            files.map(async (file, index) => {
                const binary = await convertFileToBinary(file.file); // Garantindo que o file seja um Blob/File
                const base64Binary = arrayBufferToBase64(binary);

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    identidade: `nomearq_${index + 1}`,
                    content: base64Binary
                };
            })
        );

        binaryFiles
            .then((resolvedBinaryFiles) => {
                if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                    throw new Error("Erro ao processar arquivos binários");
                }

                // Converter o ArrayBuffer para Base64
                const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                    const key = `arquivo_${index + 1}`;
                    const nomeArqKey = `nomearq_${index + 1}`;
                    return { [key]: file.content, [nomeArqKey]: file.name };
                });

                // Atualizando combinedData com o base64Files e binaryFiles
                const updatedCombinedData = [entidade, arrayBase64, resolvedBinaryFiles];

                Api.postRespostaClienteAssistencia(updatedCombinedData).then(dados => {
                    dispatch({ type: ASSISTENCIA_RESPOSTA_LOADING, payload: false })
                    resolve([])
                })
            })
            .catch((error) => {
                console.error("Erro ao processar arquivos:", error);

                reject(error);
            });


    });



