import React, { useState } from 'react';
import { IoMdWarning } from 'react-icons/io';
import { GrTooltip } from 'react-icons/gr';
import { connect } from 'react-redux';
import { FormPreDadosAction } from '../../../actions/FormActions.js'
import { useNavigate } from 'react-router-dom';


function ProductCardMobile(props) {
    const [openCard, isOpenCard] = useState(false);
    const { FormPreDadosAction } = props;
    const navigate = useNavigate(); // Hook para navegação

    const toggleCard = () => {
        isOpenCard(!openCard);
    };

    const truncateName = (name, wordLimit) => {
        const words = name.split(' ');
        if (words.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return name;
    };

    const handleOpenAssistance = (product) => {

        let categoria = product?.categoria;
        let primeiraPalavra = categoria?.split(' ')[0];

        console.log(primeiraPalavra)
        if (primeiraPalavra == 'PISOS' || primeiraPalavra == 'PISO') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Piso');
            });
        } else if (product.marca == 'ESPAÇOFIX') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Ferramentas');
            });
        } else if (product.marca == 'ESPAÇOLUX') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Lux');
            });
        } else if (primeiraPalavra == 'FORROS' || primeiraPalavra == 'FORRO' || primeiraPalavra == 'PERFIS' || primeiraPalavra == 'PERFIL') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/ForroPerfil');
            });
        } else if (primeiraPalavra == 'COLAS' || primeiraPalavra == 'COLA' || primeiraPalavra == 'RODAPES' || primeiraPalavra == 'RODAPE' || primeiraPalavra == 'PAINEL' || primeiraPalavra == 'DECKS' || primeiraPalavra == 'DECK') {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Rodape/Cola/Painel/Deck');
            });
        } else {
            FormPreDadosAction(product).then(() => {
                navigate('/assistencia/formulario/Rodape/Cola/Painel/Deck');
            });
        }

    };



    return (
        <div className='card-container-mobile'>
            <button className='button-card' onClick={toggleCard}>
                <h4 style={{ width: '250px' }}>{openCard ? props.data.nome_prod : truncateName(props.data.nome_prod, 5)}</h4>
                {props.data.nome_prod.includes('EspaçoFix') || props.data.nome_prod.includes('EspaçoFloor') || props.data.nome_prod.includes('EspaçoForro')
                    || props.data.nome_prod.includes('EspaçoLux') || props.data.nome_prod.includes('EspaçoWall') === true ? (
                    <GrTooltip color='#63ba8f' />
                ) : (
                    <IoMdWarning color='#fc9824' />
                )}
                <div className={`card-icon ${openCard ? 'open' : ''} `}>
                    <span></span>
                    <span></span>
                </div>

            </button>
            {openCard === true && (
                <div className='info-card-mobile'>
                    <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                        <h4>Quantidade:</h4>
                        <p style={{ color: '#363636' }}>{props.data.qntdade}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <h4>Valor unitário:</h4>
                        <p style={{ color: '#363636' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.data.vlr_unit)}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <h4>Fabricante:</h4>
                        <p style={{ color: '#363636' }}>{props.data.fabricante}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <h4>Ação:</h4>
                        {props.data.nome_prod.includes('EspaçoFix') || props.data.nome_prod.includes('EspaçoFloor') || props.data.nome_prod.includes('EspaçoForro')
                            || props.data.nome_prod.includes('EspaçoLux') || props.data.nome_prod.includes('EspaçoWall') === true ? (
                            <div>
                                <button
                                    className="assistencia-button"
                                    onClick={() => handleOpenAssistance(props.data)}
                                >
                                    Abrir Assistência
                                </button>
                            </div>
                        ) : (
                            <div style={{ color: '#fc9824', display: 'flex', alignItems: 'center' }}>
                                <IoMdWarning style={{ marginRight: 10 }} color='#fc9824' />
                                Abrir ticket no site da fabricante
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    form_info: state.FormReducer.info
});

export default connect(mapStateToProps, {
    FormPreDadosAction
})(ProductCardMobile);
