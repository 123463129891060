
import {
    ASSISTENCIA_ITENS_NOTA, ASSISTENCIA_LIMPA_LOGIN, ASSISTENCIA_LOADING_ITENS_NOTA,
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    itens_nota: [],
    loading_itens: false,
}
//eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ASSISTENCIA_ITENS_NOTA:
            return { ...state, itens_nota: action.payload }
        case ASSISTENCIA_LOADING_ITENS_NOTA:
            return { ...state, loading_itens: action.payload }
        case ASSISTENCIA_LIMPA_LOGIN:
            return { ...state, itens_nota: [] }
        default:
            return state; // Retorne o estado atual por padrão
    }

}