import {
    ASSISTENCIA_FORM_PRE_DADOS,
    ASSISTENCIA_LIMPA_LOGIN,
    ASSISTENCIA_FORM_DADOS,
    ASSISTENCIA_LOADING_FORM_DADOS,
    ASSISTENCIA_FORM_PADRAO_DADOS
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    form_info: [],
    form_dados: [],
    loading_form_dados: false,
    form_padrao: []
}
//eslint-disable-next-line 
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case ASSISTENCIA_FORM_PRE_DADOS:
            return { ...state, form_info: action.payload }
        case ASSISTENCIA_LIMPA_LOGIN:
            return { ...state, form_info: [] }
        case ASSISTENCIA_FORM_DADOS:
            return { ...state, form_dados: action.payload }
        case ASSISTENCIA_LOADING_FORM_DADOS:
            return { ...state, loading_form_dados: action.payload }
        case ASSISTENCIA_FORM_PADRAO_DADOS:
            return { ...state, form_padrao: action.payload }
        default:

            return state; // Retorne o estado atual por padrão
    }

}