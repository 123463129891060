import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useNavigate
import { connect } from 'react-redux';
import logo from '../../img/espacofloor.png';
import './style.css';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import ModalLogin from '../pages/Home/ModalLogin';
import { DetalhesProtocoloAction } from '../../actions/AcompanhamentoActions';
import { ItensNotaAction } from '../../actions/ListItensActions.js';
import { LogoutAction } from "../../actions/LoginActions";



function NavBar(props) {
    const [openModal, setOpenModal] = useState(false);
    const [isProtoloco, setIsProtocolo] = useState(false);
    const [modalData, setModalData] = useState({
        cpf: '',
        nota: '',
        protocolo: ''
    });
    const [isErrorCpf, setIsErrorCpf] = useState(false);
    const [isErrorNota, setIsErrorNota] = useState(false);
    const [isErrorProtocolo, setIsErrorProtocolo] = useState(false);
    const [isErrorNotFound, setIsErrorNotFound] = useState(false);
    const location = useLocation(); // Hook para pegar a rota atual


    const { loading_itens } = props;

    const navigate = useNavigate(); // Hook para navegação
    const { ItensNotaAction, DetalhesProtocoloAction, LogoutAction } = props;

    const onOpenPopup = () => {
        setOpenModal(true);
        setIsOpen(false);
    };
    const onClosePopup = () => {
        setOpenModal(false);
        setIsProtocolo(false);
        setModalData({
            cpf: '',
            nota: '',
            protocolo: '',
        });
        setIsErrorCpf(false);
        setIsErrorNota(false);
        setIsErrorProtocolo(false);
        LogoutAction()
    };

    const handleModalSubmit = () => {
        // Aqui você pode adicionar a lógica para manipular os dados do modal, se necessário
        // console.log(dadosAssistencia);


        let cpf_cnpj = modalData.cpf;
        cpf_cnpj = cpf_cnpj.replace(/[.-]/g, '');
        let nunota = modalData.nota
        nunota = nunota.replace(/[.-]/g, '');


        console.log(nunota);
        console.log(cpf_cnpj)

        if (cpf_cnpj.length < 11 && nunota.length <= 0 && isProtoloco === false) {
            setIsErrorCpf(true)
            setIsErrorNota(true)
        } else if (cpf_cnpj.length < 11 && isProtoloco === false) {

            setIsErrorCpf(true)
            setIsErrorNota(false)

        } else if (nunota.length <= 0 && isProtoloco === false) {
            setIsErrorNota(true);
            setIsErrorCpf(false)
        }
        else if (modalData.protocolo.length <= 0 && isProtoloco === true) {
            setIsErrorProtocolo(true);
        } else {
            setIsErrorCpf(false)
            setIsErrorNota(false)
            setIsErrorProtocolo(false);
            setIsErrorNotFound(null);

            // LoginAction(modalData);
            console.log(modalData)
            if (modalData.protocolo.length > 0) {
                DetalhesProtocoloAction(modalData.protocolo).then((dados) => {
                    if (dados.DataSource1[0].status === 'Erro') {
                        setIsErrorNotFound(dados.DataSource1);
                    } else {
                        navigate(`/assistencia/acompanhamento/${modalData.protocolo}`);
                    }

                })
            } else {
                ItensNotaAction(modalData).then((dados) => {
                    if (dados[0].status === 'Erro') {
                        setIsErrorNotFound(dados)
                    } else {
                        navigate('/assistencia');

                    }
                });
            }
        }

    };


    const handleInputChange = (fieldName, value) => {
        setModalData({
            ...modalData,
            [fieldName]: value
        });
        setIsErrorNotFound(null);
    };

    const handleIsProtocolo = () => {
        setIsProtocolo(!isProtoloco);
        setModalData({
            cpf: '',
            nota: '',
            protocolo: '',
        });
        setIsErrorCpf(false);
        setIsErrorNota(false);
        setIsErrorProtocolo(false);
        setIsErrorNotFound(null);
    };


    const [isOpen, setIsOpen] = useState(false);
    const sidebarRef = useRef(null);

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };


    const toggleMenu = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        if (isOpen === true) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }
    }, [isOpen]);


    return (
        <>
            <div className={props.mobile ? 'container-mobile' : 'container'}>
                <div className='container-logo'>
                    <a href="https://www.espacofloor.com.br"><img src={logo} alt='#' className='logo' /></a>
                </div>
                {props.mobile ? (
                    <>
                        <div className='container-itens menu-button'>
                            <button className={`hamburger-menu ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        <div className={`sidebar ${isOpen ? 'open' : ''}`} ref={sidebarRef}>
                            <div className="sidebar-content">
                                {location.pathname === '/' && ( // Condicional para exibir "Minhas Assistências" apenas na rota "/"
                                    <div className='sidebar-item'>
                                        {/* eslint-disable-next-line */}
                                        <a onClick={onOpenPopup} rel="noopener noreferrer"><p>Minhas Assistências</p></a>
                                    </div>
                                )}
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/" rel="noopener noreferrer"><p>Home</p></a>
                                </div>
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/espacofloor/" rel="noopener noreferrer">
                                        <p>EspaçoFloor</p>
                                        <MdOutlineKeyboardArrowRight fontSize={20} color='#b2b1ce' />
                                    </a>
                                </div>
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/espacowall/" rel="noopener noreferrer">
                                        <p>EspaçoWall</p>
                                        <MdOutlineKeyboardArrowRight fontSize={20} color='#b2b1ce' />
                                    </a>
                                </div>
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/espacoforro/" rel="noopener noreferrer">
                                        <p>EspaçoForro</p>
                                        <MdOutlineKeyboardArrowRight fontSize={20} color='#b2b1ce' />
                                    </a>
                                </div>
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/espacofix/" rel="noopener noreferrer">
                                        <p>EspaçoFix</p>
                                        <MdOutlineKeyboardArrowRight fontSize={20} color='#b2b1ce' />
                                    </a>
                                </div>
                                <div className='sidebar-item'>
                                    <a href="https://www.espacofloor.com.br/espacolux/" rel="noopener noreferrer">
                                        <p>EspaçoLux</p>
                                        <MdOutlineKeyboardArrowRight fontSize={20} color='#b2b1ce' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </>

                ) : (
                    <div className='container-itens'>
                        {location.pathname === '/' && ( // Condicional para exibir "Minhas Assistências" apenas na rota "/"
                            <span href="noreferrer" onClick={onOpenPopup}><p>Minhas Assistências</p></span>
                        )}
                        <a href="https://www.espacofloor.com.br/espacofloor/" target="_blank" rel="noopener noreferrer"><p>EspaçoFloor</p></a>
                        <a href="https://www.espacofloor.com.br/espacowall/" target="_blank" rel="noopener noreferrer"><p>EspaçoWall</p></a>
                        <a href="https://www.espacofloor.com.br/espacoforro/" target="_blank" rel="noopener noreferrer"><p>EspaçoForro</p></a>
                        <a href="https://www.espacofloor.com.br/espacofix/" target="_blank" rel="noopener noreferrer"><p>EspaçoFix</p></a>
                        <a href="https://www.espacofloor.com.br/espacolux/" target="_blank" rel="noopener noreferrer"><p>EspaçoLux</p></a>
                    </div>
                )}

            </div>
            <div className="spacer"></div>

            <ModalLogin
                onClosePopup={onClosePopup}
                openModal={openModal}
                isProtoloco={isProtoloco}
                isErrorCpf={isErrorCpf}
                modalData={modalData}
                isErrorNota={isErrorNota}
                handleIsProtocolo={handleIsProtocolo}
                isErrorProtocolo={isErrorProtocolo}
                handleModalSubmit={handleModalSubmit}
                handleInputChange={handleInputChange}
                isErrorNotFound={isErrorNotFound}
                loading={loading_itens} />
        </>
    );
}

const mapStateToProps = state => ({
    loading_itens: state.ItensNotaReducer.loading_itens
});


export default connect(mapStateToProps, {
    DetalhesProtocoloAction,
    ItensNotaAction,
    LogoutAction
})(NavBar);


