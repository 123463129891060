import {
    ASSISTENCIA_ITENS_NOTA, ASSISTENCIA_LIMPA_LOGIN, ASSISTENCIA_LOADING_ITENS_NOTA, ASSISTENCIA_ACOMPANHAMENTO, ASSISTENCIA_DETALHES_PROTOCOLO, ASSISTENCIA_DETALHES_LOADING
} from './ActionsTypes';
import { LoginAction } from './LoginActions'; // Certifique-se de que o caminho está correto

import * as Api from '../utils/API';

export const ItensNotaAction = (entidade) => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: true })
        Api.postLoginAssistencia(entidade).then(dados => {
            let erro = [{
                "status": "Erro",
                "message": "Numero de nota não aprovado!"
            }]
            if (dados.length > 0) {
                if (dados[0].status === 'Erro') {
                    dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
                    resolve(dados);
                } else {
                    dispatch(LoginAction(entidade)) // Despachar o retorno da LoginAction
                        .then(() => {
                            dispatch({ type: ASSISTENCIA_ITENS_NOTA, payload: dados });
                            dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
                            resolve(dados);
                        })
                        .catch(error => {
                            dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
                            console.log('Erro ao despachar LoginAction ->', error);
                            reject(error);
                        });
                }

            } else {
                dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
                resolve(erro);
            }

        }).catch(error => {
            console.log('DEU ERRO ->', error);
            dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })
            reject(error); // Adicionar reject para tratar o erro
        });
    });


export const LimpaListItemAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: ASSISTENCIA_ACOMPANHAMENTO, payload: [] })
        dispatch({ type: ASSISTENCIA_DETALHES_PROTOCOLO, payload: [] })
        dispatch({ type: ASSISTENCIA_LIMPA_LOGIN, payload: '' })
        dispatch({ type: ASSISTENCIA_DETALHES_LOADING, payload: false })
        dispatch({ type: ASSISTENCIA_LOADING_ITENS_NOTA, payload: false })

    });
